import logobnp from '../../Assets/logobnp.png'
import logokhimod from '../../Assets/logokhimod.png'
import logosephora from '../../Assets/logosephora.png'
import logoleroymerlin from '../../Assets/logoleroymerlin.png'
import logomontblanc from '../../Assets/logomontblanc.png'
import logofnacdarty from '../../Assets/logofnacdarty.png'
import logofortenova from '../../Assets/logofortenova.png'

export const missions = [
    {
        "title": "Online Bank Chatbot",
        "logo": logobnp,
        "date": "2024",
        "industry": "Bank",
        "location": "Paris",
        "mlExpertises": ["Chatbot", "Generative AI", "LLM", "RAG"],
        "technologies": ["Langchain", "FastAPI"],
        "client": "BNP Paribas",
        "smallDescription": "Built an online bank assistant chatbot using RAG (Retrieval Augmented Generation).",
        "longDescription": <p>🗓 January 2024 | April 2024 (4 months)<br /><br />BNP Paribas is planning to enhance its online banking experience by transitioning from a manual decision-tree chatbot to an advanced Generative AI chatbot.
            This upgrade is intended to decrease the need for call center agent involvement in addressing customer inquiries.<br />
            As the lead ML engineer of the team, my primary duty is to develop an initial version of the API that will be deployed in production to process client requests.
            The objectives include constructing the API swiftly to facilitate beta testing, adhering to best practices, and structuring it for seamless future scaling. <br />
            Moreover, a significant aspect of the project involves experimenting with Retrieval Augmented Generation (RAG) techniques,
            but also to lead workshops with BNPP IT team to design the best production-grade architecture for RAG use cases.<br />
            Additionally, my responsibilities encompassed creating a chatbot testing interface using Streamlit and designing a monitoring dashboard to manage and analyze user feedbacks.</p>
    },
    {
        "title": "Defect detection - Heat exchangers",
        "logo": logokhimod,
        "date": "2023",
        "industry": "Energy",
        "location": "Wissous",
        "mlExpertises": ["Computer Vision", "Object detection"],
        "technologies": ["Detectron2", "Ultralytics"],
        "client": "Khimod",
        "smallDescription": <p>Implemented an object detection model to spot defects on metal surfaces of heat exchangers. Contributed to the repository <a href="https://github.com/ultralytics/ultralytics/pull/6227">Ultralytics</a>.</p>,
        "longDescription": <p>🗓 October&nbsp;2023 |&nbsp;November&nbsp;2023 (6 weeks)<br />
            &nbsp;<br />
            <a href="https://www.khimod-alcen.com/">Khimod</a>, a manufacturer of heat exchangers, aims to reduce the occurrence of scratches on the metal raw materials utilized in their production process.< br />
            The manual verification of the defects is tedious and error-prone.
            My responsibility as a Senior Data Scientist was to implement in just a few weeks an object detection model able to spot tiny defects(~1 mm).< br />
            Within the limited time allocated, I successfully developed an initial algorithm that achieved 85 % recall and 30 % precision for detecting sub-millimeter defects, and 100 % recall for major scratches.
            My responsibilities encompassed the entire project lifecycle, including project delivery, backlog refinement, and hands - on involvement in all phases from capturing images to making predictions, as well as data cleaning and labeling.
            Additionally, I <a href="https://github.com/ultralytics/ultralytics/pull/6227">contributed&nbsp;</a>to the Ultralytics open source library, which was utilized in the project.</p >,
        "logoHeight": "2em",
    },
    {
        "title": "Financial forecasting",
        "logo": logosephora,
        "date": "2023",
        "industry": "Beauty",
        "location": "Neuilly",
        "mlExpertises": ["Forecasting", "Time series"],
        "technologies": ["Prophet", "XGBoost"],
        "client": "Sephora",
        "smallDescription": "Developed a forecasting model to help Sephora to estimate the sales in 5 countries 1 year ahead. Achieved a performance of +10 pts in Forecast Accuracy over current specialists forecasts.",
        "longDescription": <p>🗓 April&nbsp;2023 |&nbsp;May 2023 (6 weeks)<br /><br />
            Sephora, an international purveyor of beauty and personal care goods, seeks to enhance its budget forecasting, which currently relies on manual predictions.<br />
            In my capacity as a Senior Data Scientist, I was tasked with developing a predictive model that can accurately forecast revenue 12 months in advance across various countries and sales channels ( retail and online platforms).<br />
            Within a matter of weeks, I successfully created a machine learning model that not only outperformed the manual forecasts by over 10 pts in terms of accuracy but also diminished the forecasting bias marginally.</p>,
        "logoHeight": "1.5em",
    },
    {
        "title": "Assortment evaluation",
        "logo": logoleroymerlin,
        "date": "2022 - 2023",
        "industry": "Retail",
        "location": "Villeneuve-d'Ascq",
        "mlExpertises": ["Assortment optimization"],
        "technologies": ["Streamlit", "Google App Engine"],
        "client": "Adeo / Leroy Merlin",
        "smallDescription": "Built an assortment recommender web application used by 30+ department managers; with a monitoring dashboard.",
        "longDescription": <p>🗓 September&nbsp;2022 |&nbsp;January&nbsp;2023 (5 months)<br />
            &nbsp;< br />
            Working as a Senior Data Scientist for Leroy Merlin - a global home improvement and gardening retail chain - I was tasked with the evaluation of an assortment optimization algorithm developed by its data science team.< br />
            This algorithm aimed to tailor the product selection to align with individual store profiles, regional demands, and seasonal trends.To assess its effectiveness using practical, real-world metrics, I orchestrated the deployment of its recommendations across three pilot stores in France via a web application.< br />
            This app exposed the algorithm recommendations on a set of products(products to be added or removed from shelves) to department managers, which assessed the relevancy and applied the recommendation, when applicable.< br />
            Additionally, I engineered a monitoring dashboard to track progress through the feedback provided by the department managers and estimating the profit of the changes in assortments.</p>,
        "logoHeight": "1em",
    },
    {
        "title": "Forecasting industrialization",
        "logo": logomontblanc,
        "date": "2022",
        "industry": "Luxury",
        "location": "Switzerland",
        "mlExpertises": ["MLOPs", "Forecasting"],
        "technologies": ["Vertex Pipelines", "Cloud Scheduler", "Cloud Functions"],
        "client": "Richemont - Montblanc & IWC",
        "smallDescription": "Industrialized and automatized two forecasting pipelines using GCP services.",
        "longDescription": <p>🗓 January&nbsp;2022 |&nbsp;June 2023 (6 months)<br />
            &nbsp;< br />
            As a Machine Learning Engineer at Richemont, I was tasked with the industrialization and deployment of forecasting models developed by its data science team.These models are crucial for the Montblanc maison to accurately predict demand for both new and existing luxury products.< br />
            My responsibilities included transforming the models from their initial notebook form into production - ready packages and ensuring their operation in a live environment.This involved setting up continuous training and serving processes, which entailed weekly retraining and prediction cycles, along with monitoring forecast quality.< br />
            I played a key role in establishing the training and inference architecture on Google Cloud Platform (GCP) Vertex AI, utilizing Vertex pipelines. My contributions extended to the design of the system, including the implementation of a cloud scheduler and cloud functions, as well as making strategic decisions about the use of function-based components and the selection of Docker base images for the Vertex pipelines components.
            The infastructure was maintained using Terraform.</p>,
        "logoHeight": "1.1em",
    },
    {
        "title": "Promotion profitability estimation",
        "logo": logofnacdarty,
        "date": "2021 - 2022",
        "industry": "Retail",
        "location": "Ivry",
        "mlExpertises": ["Promotion", "Forecasting"],
        "technologies": ["Prophet", "XGBoost", "Looker Studio"],
        "client": "Fnac-Darty",
        "smallDescription": <p>Developed a counterfactual forecasting model to estimate past promotions uplift. <a href="https://medium.com/artefact-engineering-and-data-science/forecasting-something-that-never-happened-how-we-estimated-past-promotions-profitability-5f55cfa1d477">Link to Medium Article</a></p>,
        "longDescription": <p>🗓 September&nbsp;2021 |&nbsp;January 2022 (5 months)<br />
            &nbsp;<br />
            As a multinational retailer, Fnac-Darty invests heavily in orchestrating promotional campaigns to increase in-store footfall and sales.
            My responsibility as a data scientist was to evaluate the effectiveness of these promotions in the past, by estimating the sales that would have occurred without them. The difference between this estimate and the actual sales represents the incremental sales (uplift) from the promotions.
            <br />My work involved developing counterfactual forecasting models using Prophet and XGBoost, ensuring their continuous training and inference, and creating a dashboard for the promotion planning team to track the success of past promotional activities.
            <br />I synthetized the different learnings my team and I had during this project in a&nbsp;<a href="https://medium.com/artefact-engineering-and-data-science/forecasting-something-that-never-happened-how-we-estimated-past-promotions-profitability-5f55cfa1d477">Medium article</a>.</p>,
        "logoHeight": "0.5em",
    },
    {
        "title": "Promotion uplift estimation POC",
        "logo": logofortenova,
        "date": "2021",
        "industry": "Retail",
        "location": "Croatia",
        "mlExpertises": ["Promotion"],
        "technologies": ["Prophet", "Looker Studio"],
        "client": "Fortenova",
        "smallDescription": "Data science lead on the project to estimate the performance of previous promotion.",
        "longDescription": <p>🗓 August&nbsp;2021 (4 weeks)<br />
            &nbsp;< br />
            <a href="https://fortenova.hr/en/home/">Fortenova</a>, a Croatian retailer, struggles to quantify the benefits of their promotional campaigns.< br />
            As a data scientist, my responsibility on this 4-week POC was to demonstrate the value of machine learning in estimating the performance of previous promotion.< br />
            This POC was a success and led to a 2-year mission with Fortenova.</p >
    }
]
